import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Hero from "./components/hero/Hero";
import Galeria from "./components/galeria/Galeria";
import Diferenciais from "./components/diferenciais/Diferenciais";
import Unidade from "./components/unidade/Unidade";
import menuItems from "./components/shared/data/menuItems";
import socialLinks from "./components/shared/data/socialLinks";
import handleNavigationBtn from "./components/shared/utils/handleNavigationBtn";

import "./App.css";
import "./assets/styles/Reset.css";

import Sobre from "./components/sobre/Sobre";
import Contato from "./components/contato/Contato";
import Servicos from "./components/servicos/Servicos";
import InstaFeed from "./components/instaFeed/InstaFeed";

function App() {
  const main = process.env.REACT_APP_MAIN;
  const navigate = useNavigate();
  const location = useLocation();
  const [refs, setRefs] = useState({});

  useEffect(() => {
    const setupRefs = () => {
      const newRefs = {};
      menuItems.forEach((item) => {
        if (item.type === "Scroll") {
          newRefs[item.to] = React.createRef();
        }
      });
      setRefs(newRefs);
    };
    setupRefs();
  }, [menuItems]);

  const handleNavigation = async (item) => {
    await handleNavigationBtn(item, refs, navigate, location, main);
  };

  return (
    <div className="App">
      <Header
        handleNavigationBtn={handleNavigation}
        menuItems={menuItems}
        socialLinks={socialLinks}
      />
      <Routes>
        <Route
          path={main}
          element={
            <>
              <div className="body-wrapper">
                <div ref={refs.inicio}>
                  <Hero />
                </div>
                <Diferenciais />
                <div ref={refs.servicos}>
                  <Servicos />
                </div>

                <div ref={refs.sobre}>
                  <Sobre />
                </div>
                <Unidade />

                <InstaFeed />

                <Galeria />

                <div ref={refs.contato}>
                  <Contato />
                </div>

              </div>
            </>
          }
        ></Route>
      </Routes>
      <Footer
        handleNavigationBtn={handleNavigation}
        menuItems={menuItems}
        socialLinks={socialLinks}
      />
      <div className="whats-btn">
        <a href="https://wa.me/558433147436" target="_blank" rel="noreferrer">
          <i className="uil uil-whatsapp whats"></i>
        </a>
      </div>
    </div>
  );
}

export default App;
