import React, { useState, useEffect } from 'react';
import './Servicos.css';
import specialties from './servicos.json';

function Servicos({ scrollToContato }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const firstColumnItems = specialties.slice(0, Math.ceil(specialties.length / 2));
  const secondColumnItems = specialties.slice(Math.ceil(specialties.length / 2));

  return (
    <div className='Servicos-wrapper'>
      <div className='Servicos-container'>
        <h2>Serviços</h2>
        <h3>Valores e Documentos Necessários</h3>
        <div className='columns'>
          <div className='column'>
            <ul>
              {firstColumnItems.map((specialty, index) => (
                <li key={index} onClick={() => toggle(index)} className={activeIndex === index ? 'active' : ''}>
                  <div className="servicos-content-header">
                    <h3>{specialty.titulo}</h3>
                    <div className="toggle-btn">{activeIndex === index ? '-' : '+'}</div>
                  </div>
                  {activeIndex === index && (
                    <div className='servicos-content'>
                      <span><strong>Descrição:</strong> {specialty.descricao}</span>
                      <p><strong>Valor:</strong> {specialty.valor}</p>
                      <p><strong>Documentos necessários:</strong></p>
                      <ul>
                        {specialty.documentos.map((doc, docIndex) => (
                          <li key={docIndex}>
                            {docIndex < specialty.documentos.length - 1 ? `${doc};` : `${doc}.`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className='column'>
            <ul>
              {secondColumnItems.map((specialty, index) => (
                <li key={index + firstColumnItems.length} onClick={() => toggle(index + firstColumnItems.length)} className={activeIndex === index + firstColumnItems.length ? 'active' : ''}>
                  <div className="servicos-content-header">
                    <h3>{specialty.titulo}</h3>
                    <div className="toggle-btn">{activeIndex === index + firstColumnItems.length ? '-' : '+'}</div>
                  </div>
                  {activeIndex === index + firstColumnItems.length && (
                    <div className='servicos-content'>
                      <span>{specialty.descricao}</span>
                      <p><strong>Valor:</strong> {specialty.valor}</p>
                      <p><strong>Documentos necessários:</strong></p>
                      <ul>
                        {specialty.documentos.map((doc, docIndex) => (
                          <li key={docIndex}>
                            {docIndex < specialty.documentos.length - 1 ? `${doc};` : `${doc}.`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servicos;
