import React, { useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './Menu.css';
import Close from '../../assets/imgs/close.svg';

const Menu = ({
  toggleSidebar,
  isOpen,
  setIsOpen,
  handleNavigationBtn,
  menuItems,
  socialLinks,
}) => {
  const sidebarRef = useRef(null);

  const closeSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeSidebar);
    return () => {
      document.removeEventListener('mousedown', closeSidebar);
    };
  });

  const main = '/gb-principal/';

  return (
    <>
      <CSSTransition in={isOpen} timeout={500} classNames='fade' unmountOnExit>
        <div>
          <div className='sidebar' ref={sidebarRef}>
            <button
              className='menu-toggle close-menu-btn'
              onClick={toggleSidebar}
            >
              <img src={Close} alt='Close' />
            </button>

            <div className='menu-btns'>
              {menuItems.map((item, index) => (
                <li key={index}>
                  <button
                    onClick={() => [handleNavigationBtn(item), toggleSidebar()]}
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </div>

            <div className='sidebar-socials'>
              <div>Siga nossas redes sociais</div>

              <div className='social-links'>
                {socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className={`${link.icon}`} aria-label={link.label}></i>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className='curtain'></div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Menu;
