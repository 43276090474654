import React from "react";
import "./Sobre.css";
import MainBtn from "../MainBtn/MainBtn";

function Sobre() {
  return (
    <div className="sobre-wrapper">
      <div className="sobre-container">
        <div className="sobre-content">
          <h2>Sinau Inspeções</h2>
          <p>
            Sinau Inspeções é um organismo de inspeção de segurança veicular
            licenciado pelo SENATRAN, que conta com uma vasta experiência no
            segmento, tendo em vista os mais de 20 anos de história da empresa.
          </p>
          <p>
            Assim, atuamos no mercado com a força de uma equipe capacitada e com
            conhecimento de sua área de atuação para oferecer serviços de
            qualidade e com excelência.
          </p>
          <MainBtn />
        </div>
        <div className="sobre-img"></div>
      </div>
    </div>
  );
}

export default Sobre;
