import React, { useState } from "react";
import Slider from "react-slick";
import "./Galeria.css";
import Json from "./galeria.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Galeria() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          nextArrow: null,
          prevArrow: null,
        }
      }
    ]
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow custom-next`}
        style={{ ...style }}
        onClick={onClick}
      >
        <i className="uil uil-angle-right"></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow custom-prev`}
        style={{ ...style }}
        onClick={onClick}
      >
        <i className="uil uil-angle-left"></i>
      </div>
    );
  }

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="galeria-container">
      <h2>Galeria</h2>
      <Slider {...settings} className="cards-container">
        {Json.map((galeria, index) => (
          <div key={index} className="card">
            <div className="card-image" onClick={() => handleImageClick(galeria.imagem)}>
              <img src={require(`../../assets/imgs/galeria/${galeria.imagem}`)} alt={galeria.descricao} />
            </div>
          </div>
        ))}
      </Slider>

      {modalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>&times;</span>
            <img src={require(`../../assets/imgs/galeria/${selectedImage}`)} alt="Imagem ampliada" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Galeria;
