import React from "react";
import "./InstaFeed.css";
import { InstagramEmbed } from "react-social-media-embed";

function InstaFeed() {
  return (
    <div className="instaFeed-wrapper">
      <div className="instaFeed-container">
        <h2>Nos Acompanhe Pelo Instagram</h2>
        <div className="instaFeed-content">
          <div>
            <InstagramEmbed
              url="https://www.instagram.com/reel/C79iIUuuHko/"
              width={328}
            />
            <InstagramEmbed
              url="https://www.instagram.com/reel/C0CHZDFunmj/"
              width={328}
            />
            <InstagramEmbed
              url="https://www.instagram.com/reel/CsUtSzMtgH_/"
              width={328}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstaFeed;
