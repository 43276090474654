import scrollToComponent from './scrollToComponent';

const handleNavigationBtn = async (item, refs, navigate, location, main) => {
    if (item.type === 'Link') {
        navigate(item.to);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (item.type === 'Scroll') {
        if (location.pathname !== main) {
            await navigate(main);
        }
        scrollToComponent(refs, item.to);
    }
};

export default handleNavigationBtn;

