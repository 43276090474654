
import React from 'react';
import './MainBtn.css';

const Button = ({ link = "https://wa.me/558433147436", alt, children = 'Entre em contato' }) => {
    return (
        <a
            href={link}
            target='_blank'
            rel='noreferrer'
            className={`main-btn ${alt ? 'alt' : ''}`}
        >
            {children}
        </a>
    );
};

export default Button;

