const Link = 'Link';
const Scroll = 'Scroll';

const menuItems = [
  {
    label: 'Início',
    type: Scroll,
    to: 'inicio',
  },
  {
    label: 'Servicos',
    type: Scroll,
    to: 'servicos',
  },
  {
    label: 'Sobre',
    type: Scroll,
    to: 'sobre',
  },
  {
    label: 'Contato',
    type: Scroll,
    to: 'contato',
  },
];

export default menuItems;

