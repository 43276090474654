const scrollToComponent = (refs, refName) => {
    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
        const yOffset = refName === 'inicio' ? -180 : -120;
        const yCoordinate =
            selectedRef.current.getBoundingClientRect().top +
            window.scrollY +
            yOffset;
        window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
    }
};

export default scrollToComponent;

