import React from "react";
import "./Hero.css";
import MainBtn from "../MainBtn/MainBtn";

const Hero = () => {
  return (
    <div className="hero-wrapper">
      <div className="hero-overlay"></div>
      <div className="hero-container">
        <div className="hero-content">
          <h1>Segurança veicular é com a Sinau Inspeção.</h1>
          <h2>Qualidade comprovada</h2>

          <MainBtn children="Fale Conosco" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
