import React from "react";
import "./Unidade.css";
import MainBtn from "../MainBtn/MainBtn";
import Video from './unidade.mp4';

function Unidade() {
  return (
    <div className="unidade-wrapper">
      <div className="unidade-container">
        <div className="unidade-content">
          <h2>Conheça nossa unidade de inspeção</h2>
          <p>
            Venha tomar um café conosco e garantir a segurança do seu veiculo! Temos a maior estrutura de inspeção veicular do estado um espaço confortável, amplo e pronto para te recepcionar.
          </p>

          <MainBtn />
        </div>

        <div className="unidade-img">
          <video controls>
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}

export default Unidade;
